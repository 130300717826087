<template>
  <div>
    <v-card flat max-width="600px" class="mx-auto mt-12 mb-8 text-center">
      <img class="error-img mb-8" src="../assets/timeout-error.svg" />
    </v-card>
    <v-card max-width="600px" class="mx-auto px-6 py-4" elevation="6">
      <v-card-title class="text-h5 primary--text">
        对不起，您未注册本系统
      </v-card-title>
      <v-card-text>
        请联系软件厂商，并提供以下的机器码进行注册：
        <br />
        {{ baseCode }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="px-4" @click="backToLogin">
          返回登录页面
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    baseCode: {
      type: String,
      required: true
    }
  },

  methods: {
    backToLogin() {
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style lang="scss" scoped>
.error-img {
  width: 60%;
}
</style>
